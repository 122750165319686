import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import InertiaTitle from 'inertia-title/vue3';
import { backendStorage } from '@/Plugins/GlobalStorePlugin.js';
import { setupI18n } from '@/Plugins/i18n.js';
import { createMitt } from '@/Plugins/Mitt.js';
import * as Sentry from '@sentry/vue';

createInertiaApp({
  progress: false,
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
    return pages[`./Pages/${name}.vue`];
  },
  setup({ el, App, props, plugin }) {
    const globalProps = props.initialPage.props.global || {};
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(createMitt)
      .use(backendStorage, globalProps)
      .use(setupI18n, { locale: props.initialPage.props.locale })
      .use(InertiaTitle);

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.replayIntegration()],
      environment: import.meta.env.APP_ENV,
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/isap\.(me|ee)/, /^https:\/\/isapp\.(be|nl)/, /^http:\/\/isap\.localhost/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    app.mount(el);
  },
}).then(() => {
  document.getElementById('app').removeAttribute('data-page');
});
